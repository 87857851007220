<template>
    <!--培训-->
    <div>
        <el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')" :visible.sync="dlgVisible" v-model="dlgVisible"
                   :close-on-click-modal="false">
            <el-form ref="dlgFrom" :model="dlgFrom" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="dlgFrom.UserName" :disabled="true">
                                <!--<el-button slot="append" icon="el-icon-search" @click="chooseUser" v-if="operation"></el-button>-->
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公司:" prop="ApplyFactory">
                            <el-input type="text" v-model="dlgFrom.ApplyFactory" :disabled="true"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部门:" prop="ApplyDpt">
                            <el-input type="text" v-model="dlgFrom.ApplyDpt" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="ApplyPost">
                            <el-input type="text" v-model="dlgFrom.ApplyPost" :disabled="true"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目类型:" prop="ProjectType" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.ProjectType" clearable placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="管理知识" value="管理知识"></el-option>
                                <el-option label="态度意识" value="态度意识"></el-option>
                                <el-option label="岗位技能" value="岗位技能"></el-option>
                                <el-option label="安全生产" value="安全生产"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="培训方式:" prop="TrainWay" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="dlgFrom.TrainWay" clearable placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="线上" value="线上"></el-option>
                                <el-option label="线下" value="线下"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="培训名称:" prop="Apply_ProjectName">
                            <el-input type="text" v-model="dlgFrom.Apply_ProjectName" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="培训级别:" prop="Apply_Level">
                            <el-select v-model="dlgFrom.Apply_Level" clearable placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="集团级" value="集团级"></el-option>
                                <el-option label="分公司级" value="分公司级"></el-option>
                                <el-option label="部门级" value="部门级"></el-option>
                                <el-option label="商学院指派任务" value="商学院指派任务"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="培训目的:" prop="TrainTitle">
                            <el-input type="textarea" v-model="dlgFrom.TrainTitle" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="培训内容概述:" prop="TrainContent">
                            <el-input type="textarea" v-model="dlgFrom.TrainContent" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="培训对象范围概述:" prop="TrainRange">
                            <el-input type="textarea" v-model="dlgFrom.TrainRange" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="计划时间:" prop="value2111" >
                            <!--<el-date-picker type="date" v-model="dlgFrom.TrainTime" :disabled="!optType" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>-->
                            <el-date-picker v-model="value2"  :disabled="!optType"
                                            type="datetimerange"
                                            :picker-options="pickerOptions"
                                            unlink-panels
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            align="right">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
                        <el-form-item label="培训课时:" prop="TrainDate">
                            <el-input type="text" v-model="dlgFrom.TrainDate" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>-->
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="tag-group">
                            <span class="tag-group__title">讲师:   </span>
                            <el-tag v-for="tag in dlgFrom.Lecturers"
                                    :key="tag.UserName"
                                    :closable="optType"
                                    :disable-transitions="false"
                                    @close="handleClose(tag)">
                                {{tag.UserName}}
                            </el-tag>
                            <el-button slot="appendLecturer" icon="el-icon-search" @click="chooseLecturer" v-if="optType"></el-button>
                        </div>

                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="预计费用" prop="TrainMoney">
                            <el-input type="text" v-model="dlgFrom.TrainMoney" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="活动类型" prop="TMType">
                            <el-radio-group v-model="dlgFrom.TMType" :disabled="!optType">
                                <el-radio label="培训"></el-radio>
                                <el-radio label="会议"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否考核" prop="Apply_IsTest">
                            <el-radio-group v-model="dlgFrom.Apply_IsTest" :disabled="!optType">
                                <el-radio label="笔试"></el-radio>
                                <el-radio label="实操"></el-radio>
                                <el-radio label="无"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="dlgFrom.TrainText" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="!operation">
                    <vue-qr :key="qrcodeKey" ref="Qrcode" :correctLevel="3" :autoColor="false" :logoSrc="logoSrc" :text="qrCodeText" :size="300" :margin="20" :logoMargin="3" :callback="test"></vue-qr>
                    <a :href="exportLink" @click="downloadImg" :download="downloadFilename">下载二维码</a>
                </el-row>

                <upload-files :files="dlgFrom.opt1Files" :key="key1" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealopt1Files"
                              :IsDisabled="optType" :IsDel="optType" typeName="培训资料"></upload-files>

                <upload-files :files="dlgFrom.opt2Files" :key="key2" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealopt2Files"
                              :IsDisabled="operation ? false : (optType?true:false)" :IsDel="optType" typeName="现场照片"></upload-files>

                <upload-files :files="dlgFrom.opt3Files" :key="key3" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealopt3Files"
                              :IsDisabled="operation ? false : (optType?true:false)" :IsDel="optType" typeName="其他资料"></upload-files>

                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('dlgFrom')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedusers" :all="true" :single="false" @callback="checkupUsercallFunction"></UserChoose>
        </el-dialog>
        <el-dialog title="选择人员" :visible.sync="addLecturerVisible" v-model="addLecturerVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedLecturers" :all="true" :single="false" @callback="checkupLecturercallFunction"></UserChoose>
        </el-dialog>
    </div>
</template>

<script>
    import UploadFiles from "../../components/UploadFiles";
    import UserChoose from "../../components/UserChoose";
    import VueQr from 'vue-qr'
    import {
        AddTrainInfo,
        GetTrainInfo,
        UpdateTrainInfo,

        GetUserPostDptOrg
    } from "../../api/hr";
    export default {
        components: { UploadFiles, UserChoose, VueQr },
        data() {
            return {
                logoSrc: require('@/assets/grad.jpg'),
                
                exportLink: '',
                downloadFilename: '',
                qrcodeKey : 1,

                tags: [
                    { name: '标签一', type: '' },
                    { name: '标签二', type: 'success' },
                    { name: '标签三', type: 'info' },
                    { name: '标签四', type: 'warning' },
                    { name: '标签五', type: 'danger' }
                ],


                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                key1: 0,
                key2: 100,
                key3: 200,

                dlgVisible: false, // dlg 显示隐藏
         
                dlgFrom: {
                    ApplyFactory: "",
                    ApplyDpt: "",
                    ApplyPost: "",
                    Apply_QRCode: "",
                    TrainTimeStart:"2022-05-02T16:00:00.000Z",
                    TrainTimeEnd:"2022-06-22T15:59:59.000Z",
                    Lecturers: []
                },

                addUserVisible: false,
                choosedusers: [],

                addLecturerVisible: false,
                choosedLecturers: [],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            };
        },
        computed: {
            qrCodeText: function () {
                return JSON.stringify({
                    signintype: 0,
                    signincode: this.dlgFrom.Apply_QRCode,
                    signintitle: null
                });
            },
            value2:{
                // getter
                get: function () {
                    console.log("get", [this.dlgFrom.TrainTimeStart, this.dlgFrom.TrainTimeEnd])
                    return [this.dlgFrom.TrainTimeStart, this.dlgFrom.TrainTimeEnd];
                },
                // setter
                set: function (newValue) {
                    this.dlgFrom.TrainTimeStart = newValue[0];
                    this.dlgFrom.TrainTimeEnd = newValue[1];

                    console.log("set", this.dlgFrom.TrainTimeStart, this.dlgFrom.TrainTimeEnd)
                }
            }
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                //this.qrcodeKey = Math.floor(Math.random() * 100);
                this.codeUrl = "";
                if (pid == "" || pid == null) {
                    this.dlgFrom = {
                        ApplyFactory: "",
                        ApplyDpt: "",
                        ApplyPost: "",
                        Apply_QRCode: "",
                        TrainTimeStart: "",
                        TrainTimeEnd: "",
                        Lecturers:[]
                    };
                    this.codeUrl = "",
                    this.key1 += 1;
                    this.key2 += 1;
                    this.key3 += 1;
                    var user = JSON.parse(window.localStorage.user);
                    this.dlgFrom.UserId = user.sub;
                    this.dlgFrom.UserName = user.name;
                    this.dlgVisible = true;
                    GetUserPostDptOrg({ userId: user.sub }).then((res) => {
                        if (res.data.success) {
                            this.dlgFrom.ApplyFactory = res.data.response.OrganizationName;
                            this.dlgFrom.ApplyDpt = res.data.response.DepartmentName;
                            this.dlgFrom.ApplyPost = res.data.response.PostName;
                            // this.dlgFrom.DptId = res.data.response[0].DepartmentId;
                            // this.dlgFrom.JobType = res.data.response[0].PostWorkTypeId;
                            this.dlgVisible = true;
                        } else {
                            this.$message({
                                message: "获取部门信息出错",
                                type: "error",
                                duration: 5000,
                            });
                            this.dlgVisible = true;
                        }
                    });
                    //this.dlgVisible = true;
                }
                else {
                    //console.log("this", this)
                    GetTrainInfo({ id: pid }).then((res) => {

                        
                        //this.qrcodeKey = Math.floor(Math.random() * 100);
                        //console.log("this.$refs", this.qrcodeKey)
                        //this.$refs.Qrcode.main();
                        this.dlgFrom = res.data.response;
                        this.key1 += 1;
                        this.key2 += 1;
                        this.key3 += 1;
                        GetUserPostDptOrg({ userId: this.dlgFrom.UserId }).then((res) => {
                            if (res.data.success) {
                                this.dlgFrom.ApplyFactory = res.data.response.OrganizationName;
                                this.dlgFrom.ApplyDpt = res.data.response.DepartmentName;
                                this.dlgFrom.ApplyPost = res.data.response.PostName;
                                // this.dlgFrom.DptId = res.data.response[0].DepartmentId;
                                // this.dlgFrom.JobType = res.data.response[0].PostWorkTypeId;
                                this.dlgVisible = true;
                            } else {
                                this.$message({
                                    message: "获取部门信息出错",
                                    type: "error",
                                    duration: 5000,
                                });
                                this.dlgVisible = true;
                            }
                        });
                    });
                    
                    //this.dlgVisible = true;
                }
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            console.log("onSubmit", this.dlgFrom);
                            if (this.operation) {
                                //新增
                                AddTrainInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                console.log("UpdateTrainInfo", para);
                                UpdateTrainInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },

            // uploadFile Fucation
            dealopt1Files(data) {
                this.dlgFrom.opt1Files = data.backData;
            },
            dealopt2Files(data) {
                this.dlgFrom.opt2Files = data.backData;
            },
            dealopt3Files(data) {
                this.dlgFrom.opt3Files = data.backData;
            },
            // AddUser Fucation
            checkupUsercallFunction(newdata) {
                if (newdata.length > 1) {
                    this.$message({
                        message: "不允许选择多人",
                        type: "error",
                    });
                    return;
                }
                this.dlgFrom.UserId = newdata[0].Id;
                this.dlgFrom.UserName = newdata[0].UserNameClaim;
                GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
                    if (res.data.success) {
                        this.dlgFrom.ApplyFactory = res.data.response.OrganizationName;
                        this.dlgFrom.ApplyDpt = res.data.response.DepartmentName;
                        this.dlgFrom.ApplyPost = res.data.response.PostName;
                        // this.dlgFrom.DptId = res.data.response[0].DepartmentId;
                        // this.dlgFrom.JobType = res.data.response[0].PostWorkTypeId;

                    } else {
                        this.$message({
                            message: "获取部门信息出错",
                            type: "error",
                            duration: 5000,
                        });

                    }
                });
                this.addUserVisible = false;
            },
            chooseUser() {
                this.addUserVisible = true;
            },
            checkupLecturercallFunction(newdata) {
                this.dlgFrom.Lecturers.splice(0, this.dlgFrom.Lecturers.length); // 清空
                
                for (var i = 0; i < newdata.length; i++) {
                    this.dlgFrom.Lecturers.push({ UserId: newdata[i].Id, UserName: newdata[i].UserNameClaim })
                }
                this.addLecturerVisible = false;
            },
            chooseLecturer() {
                this.addLecturerVisible = true;
            },
            // 下载二维码图片
            downloadImg() {
                let Qrcode = this.$refs.Qrcode
                this.exportLink = Qrcode.$el.currentSrc
                this.downloadFilename = '二维码'
            },
            test(dataUrl, id) {
                //console.log("二维码被调用", dataUrl,this.codeUrl)
                //console.log(id)

            },
            handleClose(tag) {
                //console.log("tag", tag)
                this.dlgFrom.Lecturers.splice(this.dlgFrom.Lecturers.indexOf(tag), 1);
            },
        },
        mounted() {
            
        },
    };
</script>

<style scoped>
</style>